import auth from '../../firebase'

export const submitReset = (code, newPassword) => dispatch => {
  dispatch({ type: 'SUBMIT_RESET_REQUEST' })
  return (
    auth.confirmPasswordReset(code, newPassword)
      .then(() => {
        dispatch({ type: 'SUBMIT_RESET_SUCCESS' })
      })
      .catch(err => {
        dispatch({ type: 'SUBMIT_RESET_FAIL', payload: 'Invalid url' })
      })
  )
}
