import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import styled from 'styled-components'
import sevenLogo from '../../imgs/7-Eleven-logo-08AAB4F0FE-seeklogo.com.png'
import { LoginPageWrapper, LoginBoxWrapper } from '../../containers/LoginPage/styledComponents'
import { ContentStyle } from '../ForgotPassword/styledComponents'
import Button from '../Button'
import Popup from '../Popup'

const ConfirmWrapper = styled.div`
  margin-bottom: 24px;
  width: 266px;
`

const ResetPassword = ({ location, history, submitReset, resetResponse }) => {
  const [password, setPassword] = React.useState('')
  const [popup, setPopup] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)
  React.useEffect(() => {
    if (resetResponse.success) {
      setPopup(true)
    }
  }, [resetResponse])
  const confirmMiddle = () => {
    setPopup(false)
    history.push('/')
  }
  const middleSetPass = e => {
    setPassword(e.target.value)
    if (e.target.value.length >= 8) {
      setDisabled(false)
    } else { setDisabled(true) }
  }
  const keyObject = queryString.parse(location.search)

  return (
    <LoginPageWrapper>
      <div className="header">Chat admin panel</div>
      <div className="login-box">
        <LoginBoxWrapper>
          <div className="top-section">
            <img src={sevenLogo} alt="" />
          </div>
          <ContentStyle style={{ padding: '40px 55px' }}>
            <h3>Reset your password</h3>
            <span>
              Enter your new password to login your account. (At least 8 characters in length.)
            </span>
            <input aria-label="new-password-input" type="password" value={password} onChange={middleSetPass} />
            <div className="error-message">{resetResponse.error}</div>
            <Button
              disabled={disabled}
              onClick={() => submitReset(keyObject.oobCode, password)}
              aria-label="new-password-button"
            >
              RESET
            </Button>
          </ContentStyle>
        </LoginBoxWrapper>
      </div>
      {popup && (
        <Popup aria-label="popup-success" buttonText="CONTINUE" onClick={confirmMiddle}>
          <ConfirmWrapper>
            <h3>Password Changed!</h3>
            <span>Your password has been changed successfully.</span>
          </ConfirmWrapper>
        </Popup>
      )}
    </LoginPageWrapper>
  )
}

export default ResetPassword

ResetPassword.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  submitReset: PropTypes.func,
}
