/* istanbul ignore file */
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { signin, resendEmail } from './actions'
const LoginLoadable = lazy(() => import("."))

 /* istanbul ignore next */
const LoginPageConnector = ({ login, resendEmail, errorMessage, loading }) => (
  <Suspense fallback={<div>Loading</div>}>
    <LoginLoadable loading={loading} login={login} resendEmail={resendEmail} errorMessage={errorMessage} />
  </Suspense>
)

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(signin(username, password)),
  resendEmail: email => dispatch(resendEmail(email))
})
const mapStateToProps = state => {
  return ({
  errorMessage: state.loginReducer.errorMessage,
  loading: state.loginReducer.loading
})}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageConnector)


LoginPageConnector.propTypes = {
  test: PropTypes.string,
}
