// import { getToken, setToken, setStatus } from '../../utils/tokenService'
import produce from 'immer'

const initialState = {
  sample: '',
  reset: {
    loading: false,
    error: '',
    success: '',
  }
}

const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
    case 'SUBMIT_RESET_REQUEST':
      draft.reset.loading = true
      break
    case 'SUBMIT_RESET_SUCCESS':
      draft.reset.loading = false
      draft.reset.success = true
      break
    case 'SUBMIT_RESET_FAIL':
      draft.reset.loading = false
      draft.reset.error = action.payload
      draft.reset.success = false
      break
    case 'SUBMIT_RESET_IDLE':
      draft.reset.success = ''
      break
    default:
      return draft
    }
  })
export default appReducer
