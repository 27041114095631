import styled from 'styled-components'
export const LoginBoxWrapper = styled.div`
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);
  z-index: 1;
  .top-section {
    padding: 16px;
    color: #333;
    text-align: center;
    padding-top: 34px;
    img {
      height: 75px;
    }
  }
`
export const LoginPageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .header {
    background: rgb(255, 255, 255);
    padding: 8px;
    width: 100%;
    color: #444;
    position: absolute;
    font-size: 16px;
    padding-left: 24px;
    border-bottom: 1px solid rgb(224, 224, 224);
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.1);
  }
  .login-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1,
  }
  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    border: 1px dotted rgba(0, 33, 14, 0.1);
  }
  &:before {
    content: '';
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
    z-index: 0;
    border: 1px dotted rgba(0, 33, 14, 0.1);
  }
`
export const VersionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px;
  text-align: right;
  color: rgba(0, 0, 0, 0.37);
  font-size: 10px;
`
