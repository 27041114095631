/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components'
import roboto from './fonts/roboto-v20-latin-regular.eot'
import robotoWoff from './fonts/roboto-v20-latin-regular.woff'
import 'antd/dist/antd.css'

export const GlobalStyle = createGlobalStyle`
:root {
  h1, h2, h3, h4, h5, h6 {
    font-family: Roboto;
    color: var(--black);
    letter-spacing: normal;
    font-style: normal;
    font-stretch: normal;
  }
  h1 {
    font-size: 74px;
    font-weight: bold;
    line-height: normal;
  }
  h2 {
    font-size: 37px;
    font-weight: bold;
    line-height: normal;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
  }
  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
  }
  h5 {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }

  --color-white: white;
  --color-primary: #02a550;
  --color-active: #19ca7c;
  --color-grey-light: #f9f9f9;
  --color-grey-dark: #9b9b9b;
  --color-black: black;
  --color-text: #575757;
  --animation-bonce: cubic-bezier(0.53, 0.01, 0.35, 1.5);

  --color-btn-primary-default: #2578d4;
  --color-btn-primary-hover: #499ae9;
  --color-btn-primary-shadow: #0060a7;
  --color-btn-primary-pressed: rgba(172, 233, 250, 0.5);
  --color-btn-primary-disabled: #c8c8c8;
  --color-btn-primary-text: #f9f9f9;


  --color-btn-secondary-default:  #ffffff;
  --color-btn-secondary-border:  #2578d4;
  --color-btn-secondary-text:  #2578d4;
  --color-btn-secondary-hover:  #499ae9;
  --color-btn-secondary-hover-text:  #f9f9f9;
  --color-btn-secondary-shadow:  #0060a7;
  --color-btn-secondary-disabled-border:  #dcdcdc;
  --color-btn-secondary-disabled-text:   #c8c8c8;

  --color-btn-text-default-text:  #2578d4;
  --color-btn-text-disabled-text:  #c8c8c8;
  --color-btn-text-hover:  #499ae9;
  --color-btn-text-hover-text:  #2578d4;
  --color-btn-text-focus:  #dff3ff;
  --color-btn-text-focus-shadow:  #0060a7;
  --color-btn-text-focus-text:  #2578d4;

  --color-background-primary: #f9f9f9;


  --bo-blue900: #01285c;
  --bo-blue-700: #05408b;
  --bo-blue500: #265394;
  --bo-blue400: #4c6ca1;
  --bo-blue300: #6d85b1;
  --bo-blue200: #95a7c7;
  --bo-blue100: #bfcade;
  --cl-blue50: #e6eaf1;
  --cl-blue900: #082468;
  --cl-blue700: #154a9c;
  --cl-blue500: #2b81d9;
  --cl-blue400: #5ca8e8;
  --cl-blue300: #7ec3f3;
  --cl-blue200: #aadefb;
  --cl-blue100: #d4f0fd;
  --bo-blue50: #e6f7ff;
  --green130: #136918;
  --green110: #1f9423;
  --green90: #33b631;
  --green70: #5bd04f;
  --green50: #8fe171;
  --green30: #baed9c;
  --green10: #e0f3ca;
  --green0: #edf5e1;
  --grey130: #1f1f1f;
  --grey110: #3e3e3e;
  --grey90: #787878;
  --grey70: #a6a6a6;
  --grey50: #c8c8c8;
  --grey30: #e8e8e8;
  --grey10: #efefef;
  --grey0: #f9f9f9;
  --red130: #781800;
  --red110: #9b1e00;
  --red90: #b92100;
  --red70: #d0381b;
  --red50: #e0543f;
  --red30: #ed7b6f;
  --red10: #fab5b1;
  --red0: #fce5e5;
  --yellow130: #754f0d;
  --yellow110: #ad7804;
  --yellow90: #e9b000;
  --yellow70: #ffca08;
  --yellow50: #ffd663;
  --yellow30: #fcdf9f;
  --yellow10: #faecc5;
  --yellow0: #fcf5e1;
  --white: #ffffff;
  --black: #000000;
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(${roboto}); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('./fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url(${robotoWoff}) format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/roboto-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('./fonts/roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url(${robotoWoff}) format('woff2'), /* Super Modern Browsers */
       url('./fonts/roboto-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./fonts/roboto-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/roboto-v20-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}

html {
  font-size: 62.5%;
}
`
