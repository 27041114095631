import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  height: 48px;
  width: ${props => (props.block ? '100%' : 'unset')};
  background: ${props => {
    if (props.disabled) {
      return '#cccccc';
    }
    return (props.background ? props.background : 'var(--color-primary)')
  }};
  color: ${props => (props.color ? props.color : 'var(--color-grey-light)')};
  padding: 10px 16px;
`
