import React from 'react'
import { PopupWrapper } from './styledComponents'
import Button from '../Button'

const Popup = ({ onClick, buttonText, children }) => {
  return (
    <PopupWrapper >
      <div className="popup-box">
        {children}
        <Button aria-label="popup-button" block onClick={onClick}>{buttonText}</Button>
      </div>
    </PopupWrapper>
  )
}

export default Popup
