import styled from 'styled-components'

export const ContentStyle = styled.div`
  flex-direction: column;
  display: flex;
  background: #fff;
  height: 319px;
  span {
    font-size: 12px;
    margin-bottom: 24px;
  }
  input { height: 32px; }
  .back-link {
    text-decoration: underline;
    text-align: center;
    margin-top: 32px;
    pointer: cursor;
  }
  .error-message {
    color: #f44336;
    font-size: 12px;
    padding: 4px;
    font-family: sans-serif;
    height: 32px;
    margin-bottom: 4px;
  }
`
