import styled from 'styled-components'

export const PopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  .popup-box {
    display: flex;
    flex-direction: column;
    padding: 32px 45px;
    background: #fff;
    border-radius: 2px;
  }
`
