import React from 'react'
import PropTypes from 'prop-types'
import { ButtonWrapper } from './styledComponents'


const Button = props => {
  const { children, onClick, block } = props
  return (
    <ButtonWrapper {...props} block={block} onClick={onClick} >
      {children}
    </ButtonWrapper>
  )
}

export default Button

Button.propTypes = {
  onClick: PropTypes.func,
  block: PropTypes.bool
}
