/* istanbul ignore file */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import auth from '../../firebase'
import { submitReset } from './actions'
import App from '.'

const AppConnector = ({ submitResetPassword }) => {
  const [authUser, setAuthUser] = React.useState(true)
  auth.onAuthStateChanged(user => {
    setAuthUser(user)
  })
  return (
    <App auth={authUser} submitReset={submitResetPassword} />
  )
}

const mapDispatchToProps = dispatch => ({
  submitResetPassword: (code, pass) => dispatch(submitReset(code, pass)),
})

const mapStateToProps = state => ({
  ...state,
})

export default connect(mapStateToProps, mapDispatchToProps)(AppConnector)

AppConnector.propTypes = {
  submitResetPassword: PropTypes.func,
}
