// import { getToken, setToken, setStatus } from '../../utils/tokenService'
import produce from 'immer'

const initialState = {
  authenticated: false,
  loading: false,
  errorMessage: '',
}

const loginReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
    case 'LOGIN_AUTHENTICATED_REQUEST':
      draft.loading= true
      break
    case 'LOGIN_AUTHENTICATED_SUCCESS':
      draft.authenticated = true
      draft.loading= false
      break
    case 'LOGIN_AUTHENTICATED_FAIL':
      draft.loading= false
      break
    case 'SET_ERROR_MESSAGE':
      draft.errorMessage = action.payload
      break
    default:
      return draft
    }
  })
export default loginReducer
