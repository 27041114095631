/* istanbul ignore file */
import React from 'react'
import { connect } from 'react-redux'
import ResetPassword from '../../components/ResetPassword'
import { submitReset } from '../App/actions'

const ResetPasswordConnector = props => (
  <ResetPassword {...props } />
)

const mapDispatchToProps = dispatch => ({
  submitReset: (code, password) => dispatch(submitReset(code, password)),
})

const mapStateToProps = state => {
  return {
    resetResponse: state.appReducer.reset
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordConnector)
