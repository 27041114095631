import React from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import history from '../../history'
import auth from '../../firebase'
import MainPage from '../../components/MainPage'
import ResetPassword from '../ResetConnector'
import LoginPage from '../LoginPage/LoginpageConnector'
import { GlobalStyle } from '../../globalStyled'

const TestComponent2 = () => (<div>component2</div>)

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentUser: '' }
  }

  logout = e => {
    e.preventDefault()
    auth.signOut().then(response => {
      this.setState({
        currentUser: null
      })
    })
  }

  renderComponent() {
    if(this.props.auth) {
      return (
      <Route path="/">
        <MainPage logout={this.logout} />
      </Route>)
    }
    return (
      <Switch>
        <Route exact path="/register">
          <TestComponent2 />
        </Route>
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/">
          <LoginPage />
        </Route>
      </Switch>
    )
  }

  render() {
    return (
      <Router history={history}>
      <GlobalStyle />
      {this.renderComponent()}
      </Router>
    )
  }
}
export default App

App.propTypes = {
}
