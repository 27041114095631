import { Modal } from 'antd'
import auth from '../../firebase'

export const setErrorMessage = response => dispatch => {
  return (
    dispatch({
      type: 'SET_ERROR_MESSAGE',
      payload: response,
    })
  )
}

export const resendEmail = email => dispatch => {
  dispatch({
    type: 'RESEND_EMAIL_REQUEST'
  })
  return (auth
    .sendPasswordResetEmail(email)
    .then(res => {
      const modal = Modal.success({
        content: 'Email has been sent.',
      })
      setTimeout(() => {
        modal.destroy()
      }, 3000)
      dispatch({
        type: 'RESEND_EMAIL_SUCCESS'
      })
    })
    .catch(error => {
      dispatch({
        type: 'RESEND_EMAIL_FAIL'
      })
      if (error.code === 'auth/user-not-found') {
        // dispatch(setErrorMessage('The email is invalid.'))
        const modal = Modal.success({
          content: 'Email has been sent.',
        })
        setTimeout(() => {
          modal.destroy()
        }, 3000)
      } else {
        dispatch(setErrorMessage(error.message))
      }
    })
  )
}

export const signin = (username, password) => dispatch => {
  dispatch({
    type: 'LOGIN_AUTHENTICATED_REQUEST',
  })
  return (auth
    .signInWithEmailAndPassword(username, password)
    .then(response => {
      dispatch({
        type: 'LOGIN_AUTHENTICATED_SUCCESS',
      })
      dispatch(setErrorMessage(''))
    })
    .catch(error => {
      dispatch({
        type: 'LOGIN_AUTHENTICATED_FAIL',
      })
      switch (error.code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        dispatch(setErrorMessage('Incorrect username, password'))
        break
      case 'auth/too-many-requests':
        dispatch(setErrorMessage('Please try again later'))
        break
      case 'auth/invalid-email':
        dispatch(setErrorMessage('The email address is badly formatted.'))
        break
      case 'auth/network-request-failed':
        dispatch(setErrorMessage('Please check internet connection'))
        break
      default:
      }
    }))
}
